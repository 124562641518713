(function () {
    const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;

    /**
     *  Utilisation de local storage en fallback si indexDB n'est pas supporté
     * @returns {{getProm: (function(*): Promise<String>), set: Window.localDatabase.set, get: (function(*, *): *)}}
     */
    function useLocalStorage() {
        return window['localDatabase'] = {
            get: (key, callback) => callback(localStorage.getItem(key)),
            getProm: key => new Promise((resolve, reject) => {
                resolve(localStorage.getItem(key));
            }),
            clear: function () {
                return localStorage.clear();
            },
            clearByKey: function (key) {
                for (let i in localStorage) {
                    if (localStorage.hasOwnProperty(i)) {
                        if (i.match(query) || (!query && typeof i === 'string')) {
                            localStorage.removeItem(i);
                        }
                    }
                }
            },
            set: function (key, value, callback) {
                localStorage.setItem(key, value);
                if (callback && typeof callback === 'function') {
                    callback();
                }
            }
        }
    }

    if (!indexedDB) {
        console.error('indexDB not supported');
        //Use local storage as fallback
        return useLocalStorage();
    }
    let db, request = indexedDB.open('p2w');
    request.onsuccess = function (evt) {
        db = this.result;
    };
    request.onerror = function (event) {
        console.error('indexedDB request error');
        useLocalStorage();
    };

    request.onupgradeneeded = function (event) {
        db = null;
        const store = event.target.result.createObjectStore('s', {
            keyPath: 'key'
        });

        store.transaction.oncomplete = function (e) {
            db = e.target.db;
        };
    };

    /**
     * Vérifie si la base de donnée est prête
     * @returns {Promise<String>}
     */

    /**
     * @type {{getProm: (function(String): Promise<String>), set: ((function(string, Object, callback=): boolean)|*), clearByKey: (function(String): Promise<IDBRequest<undefined>>), clear: (function(): Promise<IDBRequest<undefined>>)}}
     */
    window['localDatabase'] = {
        /**
         * Retourne une promesse avec la valeur en BDD
         * @param key {String} clef de recherche dans la base de donnée
         * @returns {Promise<String>} promesse avec la valeur en BDD ou reject si erreur
         */
        getProm: function (key) {
            return new Promise((resolve, reject) => {
                db.transaction('s').objectStore('s').get(key).onsuccess = function (event) {
                    const result = (event.target.result && event.target.result.val) || null;
                    resolve(result);
                };
                //Handle error
                db.transaction('s').objectStore('s').get(key).onerror = function (event) {
                    reject(event);
                };
            })
        },
        /**
         * Vide la base de donnée
         * @returns {Promise<IDBRequest<undefined>>} promesse avec la valeur en BDD ou reject si erreur
         */
        clear: function () {
            return new Promise((resolve, reject) => {
                const request =  db.transaction('s', 'readwrite').objectStore('s').clear();
                request.onsuccess = function (event) {
                    resolve(event);
                };
                //Handle error
                request.onerror = function (event) {
                    reject(event);
                };
            });

        },

        /**
         * Supprime tous les éléments de la base de données locale dont la clé contient la chaîne de caractères spécifiée.
         *
         * @param {string} key - La chaîne de caractères à rechercher dans les clés des éléments à supprimer.
         * @returns {Promise} - Une promesse résolue lorsque tous les éléments correspondants ont été supprimés de la base de données, ou null si aucun élément n'a été supprimé.
         * @throws {Error} - Si une erreur se produit lors de la suppression des éléments.
         */
        clearByKey: function (key) {
            return new Promise((resolve, reject) => {
                const request = db.transaction('s').objectStore('s').getAll();
                request.onerror = function (event) {
                    reject(event);
                };
                request.onsuccess = function (event) {
                    const result = event.target.result;
                    let deleted = 0;
                    const keyPattern = window.DOSSIER_SELECT ? `${window.DOSSIER_SELECT.get('id')}_(.*)${key}$` : key;
                    const deletePromises = result.map(item => {
                        // Cherche une clé du genre <id propriete>_XXX_<key>
                        if (item.key.match(keyPattern)) {
                            return new Promise((resolve, reject) => {
                                const deleteRequest = db.transaction('s', 'readwrite').objectStore('s').delete(item.key);
                                deleteRequest.onerror = function (event) {
                                    reject(event);
                                };
                                deleteRequest.onsuccess = function (event) {
                                    deleted++;
                                    resolve(event);
                                };
                            });
                        }
                    });
                    Promise.all(deletePromises).then(() => {
                        if (deleted === 0) {
                            resolve(null);
                        } else {
                            resolve();
                        }
                    }).catch(error => {
                        reject(error);
                    });
                };
            });
        },

        /**
         * Ajoute une valeur dans la base de donnée
         * @param {string} key clef de recherche dans la base de donnée
         * @param {object} value valeur à stocker
         * @param {callback=} callback fonction de callback (optionnel)
         * @returns {boolean}
         */
        set: function (key, value, callback) {
            if ('storage' in navigator && 'estimate' in navigator.storage) {
                navigator.storage.estimate().then(function (quota) {
                    const totalSpace = quota.quota;
                    const usedSpace = quota.usage;
                    let percent = (usedSpace / totalSpace * 100).toFixed(2);
                    if (percent > 90) {
                        console.error('P2W - Storage limit reached. Please clear your cache (' + percent + '%)');
                    }
                });
            }


            const keyValue = {
                key: key,
                type: typeof value,
                val: value
            };
            let txn = db.transaction('s', 'readwrite');
            txn.oncomplete = function (event) {
                const toString$ = {}.toString;
                if (toString$.call(callback).slice(8, -1) === 'Function') {
                    callback();
                }
            }
            txn.objectStore('s').put(keyValue);
            return true;

        }
    }
})();
